<template>
    <div>
        <div class="strategy-block-1" style="background:rgba(245,245,249,1);">
            <div class="strategy-block-1-title">
                事業戦略
            </div>
            <div class="strategy-block-1-body">
                <div class="strategy-block-1-body-block">
                    <div class="strategy-block-1-body-block-header">
                        ステップ1
                    </div>
                    <div class="strategy-block-1-body-block-body">
                        <p>日本企業向け、日本オンサイト請負開発・中国オフショア開発展開</p>
                        <p>1.オフショア開発</p>
                        <p>2.マイクレーション事業</p>
                    </div>
                </div>
                <div style="display:flex;align-items:center;margin:0 25px;">
                    <img class="strategy-block-1-arrow" src="@/assets/images/company/icon_arrow.png" alt="">
                </div>
                <div class="strategy-block-1-body-block">
                    <div class="strategy-block-1-body-block-header">
                        ステップ２
                    </div>
                    <div class="strategy-block-1-body-block-body">
                        <p>日本企業のＩＴサービス・ソリューションの中国展開</p>
                    </div>
                </div>
                <div style="display:flex;align-items:center;margin:0 25px;">
                    <img class="strategy-block-1-arrow" src="@/assets/images/company/icon_arrow.png" alt="">
                </div>
                <div class="strategy-block-1-body-block">
                    <div class="strategy-block-1-body-block-header">
                        ステップ3
                    </div>
                    <div class="strategy-block-1-body-block-body">
                        <p>合コンサルティング企業へ</p>
                        <p>1.ITアーキテクチャーのコンサルティング</p>
                        <p>2.企業の総合コンサルティング</p>
                    </div>
                </div>
            </div>
        </div>
         <div class="strategy-block-1" style="padding:0 calc(100vw/1920*360);padding-bottom:calc(100vw/1920*150;">
            <div class="strategy-block-1-title">
                当社オフショアの差別化戦略
            </div>
            <div class="strategy-block-1-body">
                <div>
                    <div style="display:flex;">
                        <div>
                            <img src="@/assets/images/desc/num_1.png" alt="">
                        </div>
                        <div style="margin-left:20px;">
                            <div class="strategy-block-1-body-title">上流工程に強い</div>
                            <div class="strategy-block-1-body-container">
                                <img class="strategy-block-1-body-img" src="@/assets/images/company/icon_tab.png" alt="">
                                <div class="strategy-block-1-body-desc">
                                    日本側のコンサルタント、SEがコンサルティング・要件定義・基本設計・結合試験・総合試験などの工程を担当；
                                </div>
                            </div>
                            <div class="strategy-block-1-body-container">
                                <img class="strategy-block-1-body-img" src="@/assets/images/company/icon_tab.png" alt="">
                                <div class="strategy-block-1-body-desc">
                                    詳細設計以降の工程は、上流工程を担当したSEが日本と中国の橋渡しを行い、開発をサポート；
                                </div>
                            </div>
                             <div class="strategy-block-1-body-container">
                                <img class="strategy-block-1-body-img" src="@/assets/images/company/icon_tab.png" alt="">
                                <div class="strategy-block-1-body-desc">
                                    中国側の開発センターにて詳細設計・製造・単体テストなどの工程を担当。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="strategy-block-1-body">
                <div>
                    <div style="display:flex;">
                        <div>
                            <img src="@/assets/images/desc/num_2.png" alt="">
                        </div>
                        <div style="margin-left:20px;">
                            <div class="strategy-block-1-body-title">専門分野に強い</div>
                            <div class="strategy-block-1-body-container">
                                <img class="strategy-block-1-body-img" src="@/assets/images/company/icon_tab.png" alt="">
                                <div class="strategy-block-1-body-desc">
                                    金融分野及び業務系における専門知識を有する人材を積極的に確保する；
                                </div>
                            </div>
                            <div class="strategy-block-1-body-container">
                                <img class="strategy-block-1-body-img" src="@/assets/images/company/icon_tab.png" alt="">
                                <div class="strategy-block-1-body-desc">
                                    マイグレーション経験を有する人材を積極的に確保する；
                                </div>
                            </div>
                             <div class="strategy-block-1-body-container">
                                <img class="strategy-block-1-body-img" src="@/assets/images/company/icon_tab.png" alt="">
                                <div class="strategy-block-1-body-desc">
                                    技術者向けの社内研修を多数実施し、資格獲得を支援。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="strategy-block-1-body">
                <div>
                    <div style="display:flex;">
                        <div>
                            <img src="@/assets/images/desc/num_3.png" alt="">
                        </div>
                        <div style="margin-left:20px;">
                            <div class="strategy-block-1-body-title">プロジェクト管理に強い</div>
                            <div class="strategy-block-1-body-container strategy-block-1-body-desc">日本の御客様と中国法人の間に弊社日本法人をし、中国側の進捗管理・課題調整・品質管理等の業務を担当することで従来型の管理方式より、日本の御客様の労力削減とコスト削減を実現。</div>
                            <div>
                                <img style="width:100%;height:100%;margin-top:40px;" src="@/assets/images/company/img_zhanlue.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
@media screen and (min-width:768px) and (max-width:1920px){
    .strategy-block-1{
        padding-left:calc(100vw/1920*360);
        padding-bottom: calc(100vw/1920*30);
    }
    .strategy-block-1-title{
        font-size:calc(100vw/1920*24);
        font-family: Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*33);
        text-align: left;
        padding-top: calc(100vw/1920*30);
    }
    .strategy-block-1-body{
        display: flex;
        width: 100%;
        height: 100%;
        margin-top: calc(100vw/1920*50);
    }
    .strategy-block-1-body-block{
        width:calc(100vw/1920*340);
        height:calc(100vw/1920*400);
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
    }
    .strategy-block-1-body-block-header{
        width:100%;
        height:calc(100vw/1920*60);
        background:rgba(2,98,255,1);
        font-size:calc(100vw/1920*18);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(255,255,255,1);
        line-height:calc(100vw/1920*60);
        text-align: center !important;
    }
    .strategy-block-1-body-block p{
        font-size:calc(100vw/1920*16);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:calc(100vw/1920*32);
        text-align: left;
    }
    .strategy-block-1-body-block-body{
        padding: 20px;
    }
    .strategy-block-1-body img{
        width: calc(100vw/1920*38);
        height: calc(100vw/1920*38);
    }
    .strategy-block-1-body div{
        text-align: left;
    }
    .strategy-block-1-body-title{
        font-size:calc(100vw/1920*18);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*25);
    }
    .strategy-block-1-body-desc{
        font-size:calc(100vw/1920*16);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:calc(100vw/1920*22);
    }
    .strategy-block-1-body-img{
        width: calc(100vw/1920*20) !important;
        height: calc(100vw/1920*20) !important;
        margin-right: 10px;
    }
    .strategy-block-1-body-container{
        display: flex;
        margin-top: 25px;
    }
}
@media screen and (max-width:768px){
    .strategy-block-1{
        padding:0 20px !important;
        padding-bottom: 40px !important;
    }
    .strategy-block-1-title{
        font-size:14px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(51,51,51,1);
        line-height:20px;
        text-align: left;
        padding-top: 20px;
    }
    .strategy-block-1-body{
        /* display: flex; */
        width: 100%;
        height: 100%;
        margin-top: 20px;
    }
    .strategy-block-1-body-block{
        margin: 0 auto;
        width:80vw;
        height:200px;
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
    }
    .strategy-block-1-body-block-header{
        width:100%;
        height:40px;
        background:rgba(2,98,255,1);
        font-size:14px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:500;
        color:rgba(255,255,255,1);
        line-height:40px;
        text-align: center !important;
    }
    .strategy-block-1-body-block p{
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:14px;
        text-align: left;
    }
    .strategy-block-1-body-block-body{
        padding: 20px;
    }
    .strategy-block-1-body img{
        /* transform: rotate(90deg);
        margin: 20px auto;
        width: 30px;
        height: 30px; */
    }
    .strategy-block-1-body div{
        text-align: left;
    }
    .strategy-block-1-body-title{
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(51,51,51,1);
        line-height:14px;
    }
    .strategy-block-1-body-desc{
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:14px;
    }
    .strategy-block-1-body-img{
        width: 5px !important;
        height: 5px !important;
        margin-right: 10px;
    }
    .strategy-block-1-body-container{
        display: flex;
        margin-top: 25px;
    }
    .strategy-block-1-arrow{
        transform: rotate(90deg);
        margin: 20px auto;
        width: 30px;
        height: 30px;
    }
}
@media screen and (min-width:1920px){
    .strategy-block-1{
        padding-left:360px;
        padding-bottom: 30px;
    }
    .strategy-block-1-title{
        font-size:24px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(51,51,51,1);
        line-height:33px;
        text-align: left;
        padding-top: 30px;
    }
    .strategy-block-1-body{
        display: flex;
        width: 100%;
        height: 100%;
        margin-top: 50px;
    }
    .strategy-block-1-body-block{
        width:340px;
        height:400px;
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
    }
    .strategy-block-1-body-block-header{
        width:100%;
        height:60px;
        background:rgba(2,98,255,1);
        font-size:18px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:500;
        color:rgba(255,255,255,1);
        line-height:60px;
        text-align: center !important;
    }
    .strategy-block-1-body-block p{
        font-size:16px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:32px;
        text-align: left;
    }
    .strategy-block-1-body-block-body{
        padding: 20px;
    }
    .strategy-block-1-body img{
        width: 38px;
        height: 38px;
    }
    .strategy-block-1-body div{
        text-align: left;
    }
    .strategy-block-1-body-title{
        font-size:18px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(51,51,51,1);
        line-height:25px;
    }
    .strategy-block-1-body-desc{
        font-size:16px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:22px;
    }
    .strategy-block-1-body-img{
        width: 20px !important;
        height: 20px !important;
        margin-right: 10px;
    }
    .strategy-block-1-body-container{
        display: flex;
        margin-top: 25px;
    }
}
</style>